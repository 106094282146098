/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-02-02",
    versionChannel: "nightly",
    buildDate: "2024-02-02T00:15:06.250Z",
    commitHash: "71c9de410d64acadebb583891e684672bdda8f18",
};
